import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Label } from "components/forms";
import styled, { css } from "styled-components";
import { Body1, Body2, Helper } from "styles/Typography";

const CustomToolbar = () => (
    <div id="toolbar">
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
        <button className="ql-link"></button>
    </div>
);

const Editor = ({
    value,
    onChange,
    label,
    size = "small",
    placeholder = "",
    description = "",
    errorMessage = ""
}) => {
    const quillRef = useRef(null);

    const handleChange = (content) => {
        if (onChange) onChange(content);
    };

    return (
        <EditorGroup size={size}>
            <Label text={label} size={size} />
            <EditorContainer errorMessage={errorMessage}>
                <ReactQuill
                    ref={quillRef}
                    value={value}
                    onChange={handleChange}
                    modules={{
                        toolbar: {
                            container: '#toolbar'
                        }
                    }}
                    formats={[
                        'header', 'bold', 'italic', 'underline',
                        'list', 'bullet', 'link', 'image',
                    ]}
                    placeholder={placeholder}
                />
                <CustomToolbar />
            </EditorContainer>
            {description && (<HelperText>{description}</HelperText>)}
            {errorMessage && (<ErrorMessageText>{errorMessage}</ErrorMessageText>)}
        </EditorGroup>
    );
};

export const EditorGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${({ size }) => size === 'small' ? css`
        ${Body2}
    ` : css`
        ${Body1}
    `}
`;

export const EditorContainer = styled.div`
    .ql-tooltip {
        left: 0 !important;
    }

    .ql-container {
        min-height: 140px;
        max-height: 350px;
        overflow-y: auto;
        border: 0;
    }

    .ql-toolbar {
        border-top: 1 solid ${({theme}) => theme.colors.Neutral["60"]};
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }

    border: 1px solid ${({theme, errorMessage}) => errorMessage ? theme.colors.Red["60"] : theme.colors.Neutral["40"]};
    border-radius: 4px;

    &:hover {
        background-color: ${({theme}) => theme.colors.Neutral["15"]};
    }

    &:active {
        border: 1px solid ${({
                                 theme,
                                 errorMessage
                             }) => errorMessage ? theme.colors.Red["60"] : theme.colors.Neutral["60"]};
    }

    &:focus {
        border-color: ${({theme, errorMessage}) => errorMessage ? theme.colors.Red["60"] : theme.colors.Neutral["60"]};
        outline: none;
    }
`;

const HelperText = styled(Helper)`
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.Neutral["70"]};
`;

const ErrorMessageText = styled(Helper)`
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.Red["60"]};
`;

export default Editor;
