import React from 'react';
import styled from 'styled-components';
import { Button } from "components/forms";
import { Heading3 } from "styles/Typography";

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => `${theme.colors.Neutral["70"]}4D`};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const DialogBox = styled.div`
    background-color: ${({ theme }) => theme.colors.Neutral["0"]};
    border-radius: 12px;
    width: ${({ width }) => width || 'auto'};
    box-shadow: 0 4px 8px ${({ theme }) => `${theme.colors.Neutral["70"]}4D`};
`;

const Dialog = ({ isOpen=true, title, onClose, children, width, dialogControlButtons }) => {
    if (!isOpen) return null;

    return (
        <Overlay>
            <DialogBox width={width}>
                <DialogTitle>
                    <Heading3>{title}</Heading3>
                    <ButtonOverlay>
                        <Button icon="close-line" type="tertiary" size="small" onClick={onClose} />
                    </ButtonOverlay>
                </DialogTitle>
                <DialogBody>
                    {children}
                </DialogBody>
                {dialogControlButtons && dialogControlButtons.length > 0 && (
                    <DialogControl>
                        {dialogControlButtons}
                    </DialogControl>
                )}
            </DialogBox>
        </Overlay>
    );
};

const DialogTitle = styled.div`
    position: relative;
    width: 100%;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.Neutral["0"]};
    box-sizing: border-box;
    padding: 24px 24px 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ButtonOverlay = styled.div`
    display: flex;
    align-items: center;
`;

const DialogBody = styled.div`
    padding: 12px 24px 24px 24px;
`;

const DialogControl = styled.div`
    display: flex;
    justify-content: flex-end; 
    gap: 12px;
    border-top: 1px solid ${({ theme }) => theme.colors.Neutral["30"]}; 
    padding: 16px 24px; 
`;

export default Dialog;
