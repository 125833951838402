import React, {useEffect, useState} from 'react';

import {Input, Editor, Dialog, Button} from "components/forms";
import {getRequest, postRequest, putRequest} from "utils/api";
import {useAgendaStore} from "store/useAgendaStore";
import {useNavigate, useParams} from 'react-router-dom';


const AddEditTemplatePopUp = () => {
    const {setLoading, fetchAgendaTemplates} = useAgendaStore();
    const navigate = useNavigate();
    const { agenda_template_id } = useParams();

    const [data, setData] = useState({title: "", description: ""});
    const [error, setError] = useState({title: "", description: ""});

     const handleClose = () => {
        navigate('/agenda');
    };

    useEffect(() => {
        const fetchData = async () => {
            setData({title: "", description: ""})
            setError({});
            if (agenda_template_id) {
                setLoading(true);
                try {
                    const result = await getRequest(`/agenda/${agenda_template_id}`);
                    setData(result);
                } catch (error) {
                    const parsedError = JSON.parse(error.message);
                    console.error("Error fetching data:", parsedError.message);
                    navigate('/agenda');
                }
                finally {
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, [ agenda_template_id, setLoading, navigate]);

    const handleSave = async () => {
        setError({});
        setLoading(true);
        try {
            if (agenda_template_id) {
                await putRequest(`/agenda/${agenda_template_id}/`, data);
            } else {
                await postRequest("/agenda/", data);
            }
            handleClose();
            await fetchAgendaTemplates();
        } catch (error) {
            const parsedError = JSON.parse(error.message);
            if (parsedError.status === 422) {
                setError(parsedError.errors);
            } else {
                console.error("Error fetching data:", parsedError.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (field, value) => {
        setData(prev => ({...prev, [field]: value}));
    };

    return (
        <Dialog title={"Create agenda template"} onClose={handleClose}
                dialogControlButtons={[
                    <Button text="Cancel" type="secondary" onClick={handleClose}/>,
                    <Button text="Save" onClick={handleSave}/>
                ]}
                width={"560px"}
        >
            <Input label="Name" value={data.title} onChange={(value) => handleInputChange('title', value)}
                   errorMessage={error?.title}/>
            <Editor label="Content" value={data.description}
                    onChange={(value) => handleInputChange('description', value)} errorMessage={error?.description}/>
        </Dialog>
    );
};

export default AddEditTemplatePopUp;