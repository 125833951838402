import React, {useState} from 'react';
import styled from 'styled-components';
import {DropDownBlock, DropDownContainer} from 'components/forms';
import {Button} from "components/forms"

const ButtonWithDropDown = ({
                                onClick,
                                text,
                                icon = "",
                                iconPosition = "left",
                                disabled = false,
                                type = 'primary',
                                size = 'medium',
                                options=[],
                                optionWidth='100%'
                            }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdownVisibility = () => {
        console.log(1)
        setIsOpen(!isOpen)};

    const selectOption = (value) => {
        onClick(value);
        setIsOpen(!isOpen);
    }

    return (
        <ButtonWrapper>
            <Button
                text={text}
                icon={icon}
                iconPosition={iconPosition}
                disabled={disabled}
                type={type}
                size={size}
                onClick={toggleDropdownVisibility}
            />
            {isOpen && (
                <DropDownContainer width={optionWidth}>
                    <DropDownBlock
                        options={options}
                        onSelect={selectOption}
                    />
                </DropDownContainer>
            )}
        </ButtonWrapper>
    );
};

const ButtonWrapper = styled.div`
    position: relative;
`;
export default ButtonWithDropDown;
