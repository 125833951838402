import React from 'react';
import styled from 'styled-components';
import {Body1, Body2, Helper} from "styles/Typography";
import {Icon, Label} from "components/forms";

const Input = ({
                   label,
                   value,
                   width,
                   onChange,
                   placeholder = "",
                   description = "",
                   disabled = false,
                   icon = "",
                   iconPosition = "left",
                   size = "small",
                   errorMessage = "",
                   onClick
               }) => {

    const onChangeValue = (e) => {
        onChange(e.target.value);
    }

    return (
        <InputGroup onClick={onClick} size={size}>
            <Label text={label} size={size}/>
            <InputFieldContainer>
                <StyledTextInput
                    icon={icon}
                    iconPosition={iconPosition}
                    width={width}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChangeValue}
                    disabled={disabled}
                    errorMessage={errorMessage}
                />
                {icon && <IconContainer iconPosition={iconPosition}><Icon type={icon} size={getIconSize(size)}/></IconContainer>}
            </InputFieldContainer>
            {description && (<HelperText>{description}</HelperText>)}
            {errorMessage && (<ErrorMessageText>{errorMessage}</ErrorMessageText>)}
        </InputGroup>
    );
};

const getIconSize = (size) => {
    return size === 'small' ? 16 : 18;
};

const getInputHeight = (size) => {
    return size === "small" ? "32px" : "40px";
};

const getInputGroupStyles = (size) => {
    return size === 'small' ? Body2 : Body1;
};

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${({size}) => getInputGroupStyles(size)}
`;

const InputFieldContainer = styled.div`
    position: relative;
    width: fit-content;
`;

const IconContainer = styled.div`
    position: absolute;
    ${({iconPosition}) => iconPosition === 'left' ? 'left: 10px;' : 'right: 10px;'}
    top: 55%;
    transform: translateY(-50%);
    pointer-events: none;
`;

export const StyledTextInput = styled.input`
    padding: ${({
                    icon,
                    iconPosition
                }) => icon ? (iconPosition === "left" ? "10px 10px 10px 36px" : "10px 36px 10px 10px") : "10px"};
    box-sizing: border-box;
    width: ${({width}) => width?  width : '100%'};
    height: ${({size}) => getInputHeight(size)};
    
    border: 1px solid ${({theme, errorMessage}) => errorMessage ? theme.colors.Red["60"] : theme.colors.Neutral["40"]};
    border-radius: 4px;
    &:hover {
        background-color: ${({theme}) => theme.colors.Neutral["15"]};
    }
    &:active {
        border: 1px solid ${({theme, errorMessage}) => errorMessage ? theme.colors.Red["60"] : theme.colors.Neutral["60"]};
    }
    &:focus {
        border-color: ${({theme, errorMessage}) => errorMessage ? theme.colors.Red["60"] : theme.colors.Neutral["60"]};
        outline: none;
    }
`;

const HelperText = styled(Helper)`
    margin-top: 2px;
    color: ${({theme}) => theme.colors.Neutral["70"]};
`;

const ErrorMessageText = styled(Helper)`
    margin-top: 2px;
    color: ${({theme}) => theme.colors.Red["60"]};
`;
export default Input;
