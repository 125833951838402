import React from 'react';
import styled, {keyframes} from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => `${theme.colors.Neutral["70"]}4D`};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const Spinner = styled.div`
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: ${spin} 1s linear infinite;
`;


const Loading = ({isLoading}) => {
    if (!isLoading) return null;
    return (
        <LoadingContainer>
            <div>
                <Spinner/>
            </div>
        </LoadingContainer>
    );
};

export default Loading;
