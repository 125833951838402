import {Heading2, Heading3, Helper} from "styles/Typography";
import styled from "styled-components";
import ItemList from "./Template/ItemList";
import MeetingList from "./Meeting/List";
import {Button,Loading} from "components/forms";
import React, {useState} from "react";
import Banner from "./Banner";
import {useAgendaStore} from 'store/useAgendaStore';
import {Outlet, useNavigate} from "react-router-dom";

const AgendaPage = () => {
    const navigate = useNavigate();
    const {isLoading, showMeeting, toggleShowMeeting} = useAgendaStore();

    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;
        setIsScrolled(scrollTop > 10);  // якщо прокрутили більше ніж на 10 пікселів, ховаємо Helper
    };

    return (
        <PageContainer>
            <Loading isLoading={isLoading}/>
            <AgendaContent>
                <AgendaHeader>
                    <Heading2>Agenda</Heading2>
                    <LinksContainer>
                        <Button text="How it works?" type={'tertiary'}/>
                        {!showMeeting &&
                            <Button text="Meetings w/o agenda" type={'secondary'} onClick={() => toggleShowMeeting()}/>}
                        <Button icon="add-line" type={'primary'} onClick={() =>  navigate(`/agenda/add`)}/>
                    </LinksContainer>
                </AgendaHeader>
                <div>
                    <Banner/>
                    <AgendaMain>
                        <ItemList/>
                    </AgendaMain>
                </div>
            </AgendaContent>
            {showMeeting &&
                <MeetingContent onScroll={handleScroll}>
                    <MeetingHeader>
                        <div>
                            <Heading3>Meetings without agenda</Heading3>
                            {!isScrolled && (
                                <div>
                                    <Helper>Additional description with info that we show meetings where you are
                                        organizer</Helper>
                                </div>
                            )}
                        </div>
                        <LinksContainer>
                            <Button icon="close-line" type='tertiary' onClick={() => toggleShowMeeting()}/>
                        </LinksContainer>

                    </MeetingHeader>
                    <MeetingMain>
                        <MeetingList/>
                    </MeetingMain>
                </MeetingContent>
            }
            <Outlet/>
        </PageContainer>
    );
};

const PageContainer = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden;
`;

const AgendaContent = styled.div`
    flex: 1;
`;

const AgendaHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 32px;
    border-bottom: 1px solid ${({theme}) => theme.colors.Neutral["30"]};
    justify-content: space-between;
`;

const LinksContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const AgendaMain = styled.div`
    overflow-y: auto;
    height: calc(100% - 121px);
    padding: 20px;
`;

const MeetingContent = styled.div`
    width: 333px;
    overflow-y: auto;
    background-color: ${({theme}) => theme.colors.Neutral["15"]};
`;

const MeetingHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid ${({theme}) => theme.colors.Neutral["30"]};
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: ${({theme}) => theme.colors.Neutral["15"]};
    z-index: 100;
    transition: background-color 0.3s;
`;

const MeetingMain = styled.div`
    padding: 20px;
`;

export default AgendaPage;
