import {Subheader1, Helper} from "styles/Typography";
import styled from "styled-components";
import {useState} from "react";
import {ButtonWithDropDown} from "components/forms";

import {useNavigate} from 'react-router-dom';

const Item = ({template_id, name, description}) => {
    const navigate = useNavigate();
    const [isHover, setIsHover] = useState(false);

    const options = [
        {value: "edit", label: "Edit"},
        {value: "delete", label: "Delete"},
    ]

    const handleChange = (value) => {
        if (value === "edit") {
            navigate(`/agenda/${template_id}/edit`);
        }
        if (value === "delete") {
            navigate(`/agenda/${template_id}/delete`);
        }
    }

    return (
        <ItemContainer
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <ItemName>
                <Subheader1>{name}</Subheader1>
                {isHover && <ButtonWithDropDown icon="more-2-fill" type="secondary" size="small" options={options}
                                                optionWidth={'100px'} onClick={handleChange}/>}
            </ItemName>
            <ItemDescription>
                <Helper dangerouslySetInnerHTML={{__html: description}}/>
            </ItemDescription>
        </ItemContainer>
    )
};


const ItemContainer = styled.div`
    height: 136px;
    width: 280px;
    padding: 16px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: ${({theme}) => theme.colors.Neutral["15"]};

    &:hover {
        box-shadow: 0 0 0 1px ${({theme}) => theme.colors.Neutral["50"]};
    }
`

const ItemName = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({theme}) => theme.colors.Neutral["80"]};
    margin-bottom: 12px;
    height: 24px;
`;

const ItemDescription = styled.div`
    text-align: left;
    color: ${({theme}) => theme.colors.Neutral["60"]};
`
export default Item;